<template>
  <div>
    <router-link class="come-back" to="/admin/connect_contacts">
      <div></div>
      Вернуться назад
    </router-link>

    <h2 class="title_categories">Редактирование контакта</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createContact" ref="createContact">
      <div class="inputs__container">
        <div class="content-sb">
          <p>Телефон<span>*</span></p>
          <input required placeholder="Введите телефон" v-model="hotLine" type="text" name="title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Электронная почта<span>*</span></p>
          <input required placeholder="Введите электронную почту" v-model="email" type="text" name="workDay" class="input blue">
        </div>
        <!-- <div class="content-sb">
          <p>График работы<span>*</span></p>
          <input required placeholder="Введите график работы" v-model="contactsBlocks[0].title" type="text" name="workDay" class="input blue">
        </div>
        <div class="content-sb">
          <p>Часы работы<span>*</span></p>
          <input required placeholder="Введите часы работы" v-model="contactsBlocks[0].time" type="text" name="workTime" class="input blue">
        </div> -->

        <!-- <div v-for="(contactBlock, index) in contactsBlocks" :key="`contactBlock${index}`" class="contacts__block">
            <header v-if="index!==0">
                <h6> Блок графика работы №{{ index+1 }}</h6>
                <img src="/assets/icons/close.svg" @click="deleteContactBlock(index)">
            </header>
            <div class="content-sb" v-if="index!==0">
                <p>График работы<span>*</span></p>
                <input required placeholder="Введите график работы" v-model="contactsBlocks[index].title" type="text" name="workDay" class="input blue">
            </div>
            <div class="content-sb" v-if="index!==0">
                <p>Часы работы<span>*</span></p>
                <input required placeholder="Введите часы работы" v-model="contactsBlocks[index].time" type="text" name="workTime" class="input blue">
            </div>
        </div>
        <div class="btn-blue" @click="addBlock()">
          Добавить блок
        </div> -->
      </div>

      <!-- <div class="inputs__container">
        <div class="content-sb">
          <p>График работы (Англ)<span>*</span></p>
          <input required placeholder="Введите график работы (Англ)" v-model="contactsBlocksEng[0].title" type="text" name="workDay" class="input blue">
        </div>
        <div class="content-sb">
          <p>Часы работы (Англ)<span>*</span></p>
          <input required placeholder="Введите часы работы (Англ)" v-model="contactsBlocksEng[0].time" type="text" name="workTime" class="input blue">
        </div>

        <div v-for="(contactBlock, index) in contactsBlocksEng" :key="`contactBlock${index}`" class="contacts__block">
          <header v-if="index!==0">
            <h6> Блок графика работы №{{ index+1 }} (Англ)</h6>
            <img src="/assets/icons/close.svg" @click="deleteContactBlockEng(index)">
          </header>
          <div class="content-sb" v-if="index!==0">
            <p>График работы (Англ)<span>*</span></p>
            <input required placeholder="Введите график работы (Англ)" v-model="contactsBlocksEng[index].title" type="text" name="workDay" class="input blue">
          </div>
          <div class="content-sb" v-if="index!==0">
            <p>Часы работы (Англ)<span>*</span></p>
            <input required placeholder="Введите часы работы (Англ)" v-model="contactsBlocksEng[index].time" type="text" name="workTime" class="input blue">
          </div>
        </div>
        <div class="btn-blue" @click="addBlockEng()">
          Добавить блок (Англ)
        </div>
      </div> -->

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/connect_contacts" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'CreateConnectContacts',

  data () {
    return {
      routes: this.$route.params.id,
      hotLine: '',
      email: '',
      contactsBlocks: [
        {
          title: '',
          time: ''
        }
      ],
      contactsBlocksEng: [
        {
          title: '',
          time: ''
        }
      ]
    }
  },

  watch: {
    '$store.getters.getHotLine' () {
      const dataFromServer = this.$store.getters.getHotLine
      this.hotLine = dataFromServer.phone
      this.email = dataFromServer.email
      this.contactsBlocks = dataFromServer.work_time.map((block) => { return { ...block } })
      this.contactsBlocksEng = dataFromServer.work_time_eng.map((block) => { return { ...block } })
    }
  },

  created () {
    this.$store.dispatch('setHotLine')
  },

  methods: {
    createContact () {
      const formData = new FormData()
      formData.append('phone', this.hotLine)
      formData.append('email', this.email)
      formData.append('work_time', JSON.stringify(this.contactsBlocks))
      formData.append('work_time_eng', JSON.stringify(this.contactsBlocksEng))
      this.$store.dispatch('editHotLine', formData)
    },

    addBlock () {
      this.contactsBlocks.push({
        title: '',
        time: ''
      })
    },
    addBlockEng () {
      this.contactsBlocksEng.push({
        title: '',
        time: ''
      })
    },

    deleteContactBlock (index) {
      this.contactsBlocks.splice(index, 1)
    },

    deleteContactBlockEng (index) {
      this.contactsBlocksEng.splice(index, 1)
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";

  .wysiwyg {
    width: 33.75rem;
  }

  .contacts__block {
    border-bottom: 1px solid #D5D6D9;
    margin-bottom: 1rem;

    header {
      margin-bottom: .75rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > h4 {
        font-size: .875rem;
        line-height: 1.375rem;
      }

      > img {
        width: 1.5rem;
        height: 1.5rem;
        object-fit: contain;
        object-position: center;
        cursor: pointer;
      }
    }
  }

  .btns-create-form {
    border-top: 1px solid #D5D6D9;
    padding-top: 2.5rem;
    margin-top: 2.5rem;
  }

  .content-sb {
    margin-bottom: 1.25rem;

    span {
      color: red;
    }
  }

  .select, .input {
    width: 33.75rem;
  }

  .inputs__container {
    margin-bottom: 2rem;
    // border-bottom: 1px solid #D5D6D9;
  }

  .content-blocks__container {
    display: flex;
    flex-direction: column;

    > .content-block {
      margin-bottom: 1.875rem;

      header {
        margin-bottom: .75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > h4 {
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
        }

        > img {
          width: 1.5rem;
          height: 1.5rem;
          object-fit: contain;
          object-position: center;
          cursor: pointer;
        }
      }

      .data-card {
        background: white;
        border: 1px solid #EBF3FF;
        box-sizing: border-box;
        box-shadow: 0 .9375rem 1.5625rem rgba(189, 205, 221, 0.15);
        border-radius: .5rem;
        padding: 1.25rem;
        width: 100%;
        min-height: 8.875rem;
      }
    }
  }

  .add-content-block__container {
    background-color: $neutral-placeholder;
    width: 100%;
    padding: 1.25rem;
    border-radius: .187rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-top: .5rem;

    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1.5rem;
      background-color: $neutral-placeholder;
      top: 0;
      left: 50%;
      transform: translate(-50%,-50%) rotate(45deg);
    }

    > .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1.25rem;
    }

    > .btn-blue > img {
      width: 1.125rem;
      height: 1.125rem;
      object-fit: contain;
      object-position: center;
      margin-right: .5rem;
    }
  }
</style>
